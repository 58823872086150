import $http from '@axios'

export default {
  namespaced: true,
  state: {
    step: 1,
  },
  getters: {},
  mutations: {
    setStep(state, value) {
      state.formPayload = value
    },
  },
  actions: {
    postData() {
      return new Promise((resolve, reject) => {
        $http.post('/toggle-finish-tutorial')
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
