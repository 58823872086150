import $http from '@axios'

export default {
  namespaced: true,
  state: {
    formPayload: {
      name: '',
      city: '',
      photo: '',
      address: '',
    },
  },
  getters: {},
  mutations: {
    setFormPayload(state, value) {
      state.formPayload = value
    },
  },
  actions: {
    getData(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/merchantBranch/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    changeBranch(context, { user_id, payload }) {
      return new Promise((resolve, reject) => {
        $http.post(`/users/change-branch/${user_id}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, query }) {
      return new Promise((resolve, reject) => {
        $http.post(`/merchantBranch/${query || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    deleteData(context, query) {
      return new Promise((resolve, reject) => {
        $http.delete(`/merchantBranch/${query || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
