import $http from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        getData(context, {params}) {
            return new Promise((resolve, reject) => {
                $http.get(`/petty-cash`, { params })
                    .then(result => {
                        resolve(result)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getDetail(context, { payload, uuid }) {
            return new Promise((resolve, reject) => {
                $http.get(`/petty-cash/${uuid || ''}`, payload)
                    .then(result => {
                        resolve(result)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        postOpen(context, { payload }) {
            return new Promise((resolve, reject) => {
                $http.post(`/petty-cash`, payload)
                    .then(result => {
                        resolve(result)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        postAdd(context, { payload, uuid }) {
            return new Promise((resolve, reject) => {
                $http.post(`/petty-cash/${uuid || ''}/additional`, payload)
                    .then(result => {
                        resolve(result)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        postClose(context, { payload, uuid }) {
            return new Promise((resolve, reject) => {
                $http.post(`/petty-cash/${uuid || ''}/close`, payload)
                    .then(result => {
                        resolve(result)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
    }
}