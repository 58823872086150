/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import Vue from "vue";
// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    Accept: "application/json",
    // 'Cache-Control': 'no-cache',
  },
  // adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter)),
});

Vue.prototype.$http = axiosIns;

axiosIns.interceptors.request.use(
  (request) => {
    request.headers.Referer = window.location.href;
    // request.headers.Referer = window.location.href;
    request.headers["Full-URL"] = window.location.href;
    if (request.data) {
      // request.headers.common['Content-Type'] = 'multipart/form-data'
    }
    return request;
  },
  (error) => Promise.reject(error)
);

axiosIns.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    }
    return Promise.reject(response);
  },
  (error) => {
    if (error.response.status === 401) {
      Vue.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Login Gagal!",
          icon: "DangerIcon",
          variant: "danger",
          text:
            (error.response.data &&
              error.response.data.meta &&
              error.response.data.meta.messages.join(", ")) ||
            "",
        },
      });
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userData");
      localStorage.removeItem("permissions");
      if (window.location.pathname !== "/login") {
        window.location.href = `${window.location.origin}/login`;
      }
    }

    if (error.response.status === 400 || error.response.status === 403) {
      const { messages } = error.response.data.meta;
      if (messages.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < messages.length; index++) {
          const element = messages[index];
          Vue.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Oops!",
              icon: "DangerIcon",
              variant: "danger",
              text: `${element}`,
            },
          });
        }
      } else {
        for (const key in messages) {
          if (Object.hasOwnProperty.call(messages, key)) {
            const element = messages[key];
            Vue.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Oops!",
                icon: "DangerIcon",
                variant: "danger",
                text: `${element.join(", ")}`,
              },
            });
          }
        }
      }
    }

    if (error.response.status === 403 && !localStorage.getItem("otp_type")) {
      // window.location.href = `${window.location.origin}/error-403`
    }

    return Promise.reject(error);
  }
);

export default axiosIns;
