// import $http from '@axios'

export default {
  namespaced: true,
  state: {
    step: 1,
  },
  getters: {},
  mutations: {
    setStep(state, value) {
      state.step = value
    },
  },
  actions: {
  },
}
