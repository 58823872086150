import $http from '@axios'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    formPayload: {},
  },
  getters: {},
  mutations: {
    setFormPayload(state, value) {
      state.formPayload = value
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
  },
  actions: {
    submitCheckout(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/cart/checkout/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
