import $http from '@axios'

export default {
  namespaced: true,
  state: {
    totalNotApproved: 0,
    resultProduct: {},
    selectedWarehouse: {},
    isDetail: null,
    detailItem: {},
  },
  getters: {},
  mutations: {
    setTotalNotApproved(state, value) {
      state.totalNotApproved = value
    },
    setSelectedWarehouse(state, value) {
      state.selectedWarehouse = value
    },
    setResultProduct(state, value) {
      state.resultProduct = value
    },
    setIsDetail(state, value) {
      state.isDetail = value
    },
    setDetailItem(state, value) {
      state.detailItem = value
    },
  },
  actions: {
    // Stock Warehouse
    getData(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`stocks/opname/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, type }) {
      return new Promise((resolve, reject) => {
        $http.post(`stocks/opname/${type || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    deleteData(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http.delete(`stocks/opname/${uuid}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
