import $http from '@axios'

export default {
  namespaced: true,
  state: {
    editId: null,
    formPayload: {
      product_type: 'parent',
      image: '',
      item_uuid: '',
      specification: '',
      uom_uuid: '',
      alias_name: '',
      sku_code: 0,
      brand_uuid: '',
    },
    retail: {
      image: '',
      retail_parent_uuid: '',
      uom_uuid: '',
      retail_convertion: 0,
      alias_name: '',
    },
    product: {
      product_type: 'parent',
      image: '',
      // item_uuid: '',
      // specification: '',
      uom_uuid: '',
      // brand_uuid: '',
      alias_name: '',
      // sku_code: 0,
    },
    discounts: {
      standard_price: 0,
      minimum_price: 0,
      discounts: [],
      discount_start_date: '',
      discount_end_date: '',
    },
    initial_stocks: [
      {
        warehouse_uuid: '',
        note: '',
        stock: 0,
      },
    ],
    image_url: '',
    result: {},
    history: null,
    uom: '',
    arrange: false,
  },
  getters: {},
  mutations: {
    setArrange(state, value) {
      state.arrange = value
    },
    setUom(state, value) {
      state.uom = value
    },
    setProduct(state, value) {
      state.product = value
    },
    setRetail(state, value) {
      state.retail = value
    },
    setDiscounts(state, value) {
      state.discounts = value
    },
    setInitialStocks(state, value) {
      state.initial_stocks[0] = value
    },
    setEditId(state, value) {
      state.editId = value
    },
    setHistory(state, value) {
      state.editId = value
    },
    setFormPayload(state, value) {
      state.formPayload = value
    },
    setResult(state, value) {
      state.result = value
    },
    setImageUrl(state, value) {
      state.image_url = value
    },
  },
  actions: {
    // Product Out Of Stocks
    outOfStocks(context, { params }) {
      return new Promise((resolve, reject) => {
        $http.get('/product-runout-soon', { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getData(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/product/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getBestSeller(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/product-best-seller/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getPrice(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/product/${uuid}/harga-modal`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getStock(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/product/${uuid}/incoming-stock`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getHistory(context, { id, params }) {
      return new Promise((resolve, reject) => {
        $http.get(`/product/${id}/history`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, query }) {
      return new Promise((resolve, reject) => {
        $http.post(`/product${query || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    deleteData(context, query) {
      return new Promise((resolve, reject) => {
        $http.delete(`/product${query || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    clearItem(context) {
      context.commit('setEditId', null)
      context.commit('setImageUrl', null)
      context.commit('setFormPayload', {
        product_type: '',
        image: '',
        item_uuid: '',
        specification: '',
        uom_uuid: '',
        alias_name: '',
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        discount_percents: [],
        discount_fixed: 0,
        discount_start_date: '',
        discount_end_date: '',
        warehouses_uuid: [],
      })
    },
  },
}
