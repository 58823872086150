import $http from '@axios'

export default {
  namespaced: true,
  state: {
    formPayload: {
      name: '',
      description: '',
      logo: '',
    },
  },
  getters: {},
  mutations: {
    setFormPayload(state, value) {
      state.formPayload = value
    },
  },
  actions: {
    getData(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/bank${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, query }) {
      return new Promise((resolve, reject) => {
        $http.post(`/bank${query || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    deleteData(context, query) {
      return new Promise((resolve, reject) => {
        $http.delete(`/bank${query || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
