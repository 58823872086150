import $http from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    // getData(context) {
    //   return new Promise((resolve, reject) => {
    //     $http
    //       .get(`/subscription`)
    //       .then((result) => {
    //         resolve(result);
    //       })
    //       .catch((err) => {
    //         reject(err);
    //       });
    //   });
    // },
    postTambahan(context, { payload }) {
      return new Promise((resolve, reject) => {
        $http
          .post(`/subscription`, payload)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDetailTambahan(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/subscription/${uuid}`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getListTambahan(context, { params }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/subscription`, { params })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteData(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .post(`/subscription/${uuid}/cancel`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
