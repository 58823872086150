import $http from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getData(context, { params }) {
      return new Promise((resolve, reject) => {
        $http.get('/admin/acl/roles', { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, query }) {
      return new Promise((resolve, reject) => {
        $http.post(`/admin/acl/roles/${query || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
