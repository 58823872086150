import $http from '@axios';

export default {
  namespaced: true,
  state: {
    changelogs: [],
  },
  mutations: {
    setChangelogs(state, changelogs) {
      state.changelogs = changelogs;
    },
  },
  actions: {
    fetchData({ commit }) {
      return new Promise((resolve, reject) => {
        $http.get(`/app-changelog`)
          .then(result => {
            commit('setChangelogs', result.data.data);
            resolve();
          }).catch(err => {
            reject(err);
          });
      });
    },
  },
  getters: {
    getChangelogs(state) {
      return state.changelogs;
    },
  },
};
