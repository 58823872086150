import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
// eslint-disable-next-line no-unused-vars
export const getHomeRouteForLoggedInUser = userRole => '/welcome'

/**
 * Check is user have permission.
 *
 * @param String permission
 *
 * @returns boolean
 */
export const checkPermission = permissions => {
  if (!permissions) {
    return true;
  }

  const userPermissions = JSON.parse(localStorage.getItem('userPermission'));

  if (Array.isArray(permissions)) {
    // Check if the user has at least one of the required permissions
    return permissions.some(permission => userPermissions.includes(permission));
  } else {
    // Check if the user has the single required permission
    return userPermissions.includes(permissions);
  }
};

/**
 *
 * Standard success notification.
 *
 * @param {*} context This Context
 * @param String title
 * @param String message
 */
export const successNotification = (context, title, message) => {
  context.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title,
      icon: 'CheckIcon',
      variant: 'success',
      text: message,
    },
  })
}

export const downloadNotification = (context, title, message) => {
  context.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title,
      icon: 'DownloadIcon',
      variant: 'info',
      text: message,
    },
  })
}

/**
 *
 * Standard error notification.
 *
 * @param {*} context This Context
 * @param String title
 * @param String|Array message
 */
export const errorNotification = (context, title, message) => {
  if (Array.isArray(message)) {
    message.forEach(item => {
      context.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon: 'DangerIcon',
          variant: 'danger',
          text: item,
        },
      })
    })
  } else {
    context.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title,
        icon: 'DangerIcon',
        variant: 'danger',
        text: message,
      },
    })
  }
}

export const removeArray = arr => {
  // eslint-disable-next-line prefer-rest-params, no-undef
  let what; const a = arguments; let L = a.length; let
    ax
  while (L > 1 && arr.length) {
    // eslint-disable-next-line no-plusplus
    what = a[--L]
    // eslint-disable-next-line no-cond-assign
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1)
    }
  }
  return arr
}

export const preformatFloat = float => {
  if (!float) {
    return ''
  }

  // Index of first comma
  const posC = float.indexOf(',')

  if (posC === -1) {
    // No commas found, treat as float
    return float.replace(/\./g, '')
  }

  // Index of first full stop
  const posFS = float.indexOf('.')

  if (posFS === -1) {
    // Uses commas and not full stops - swap them (e.g. 1,23 --> 1.23)
    return float.replace(/\,/g, '.')
  }

  // Uses both commas and full stops - ensure correct order and remove 1000s separators
  return ((posC < posFS) ? (float.replace(/\,/g, '')) : (float.replace(/\./g, '').replace(',', '.')))
}

export const formatRupiah = (angka, prefix) => {
  const numberString = angka.replace(/[^,\d]/g, '').toString()
  const split = numberString.split(',')
  const sisa = split[0].length % 3
  let rupiah = split[0].substr(0, sisa)
  const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

  if (ribuan) {
    const separator = sisa ? '.' : ''
    rupiah += separator + ribuan.join('.')
  }

  rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah
  return prefix === undefined ? rupiah : (rupiah || '')
}

export const isEmail = email => {
  const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
  return regex.test(email)
}
