import $http from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        getData(context) {
            return new Promise((resolve, reject) => {
                $http.get(`/subscription-renewal`)
                    .then(result => {
                        resolve(result)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        postSubscription(context, { payload }) {
            return new Promise((resolve, reject) => {
                $http.post(`/subscription-renewal-payment`, payload)
                    .then(result => {
                        resolve(result)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getDetailSubscription(context, { uuid }) {
            return new Promise((resolve, reject) => {
                $http.get(`/subscription-renewal-payment/${uuid}`)
                    .then(result => {
                        resolve(result)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getListSubscription(context, { params }) {
            return new Promise((resolve, reject) => {
                $http.get(`/subscription-renewal-payment`, { params })
                    .then(result => {
                        resolve(result)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        deleteData(context, { uuid }) {
            return new Promise((resolve, reject) => {
              $http.post(`/subscription-renewal-payment/${uuid}/cancel`)
                .then(result => {
                  resolve(result)
                }).catch(err => {
                  reject(err)
                })
            })
          },    }
}